.ImageCarousel {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 300px;
  padding: 20px;
  box-sizing: border-box;
}

.image {
  width: 100%;
}
