.Ticker {
  position: absolute;
  bottom: 2em;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.message {
  background-color: rgba(black, 0.7);
  background: linear-gradient(
    to bottom right,
    rgba(darken(#381048, 5), 0.7),
    rgba(black, 0.7)
  );
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}
