.ProgressBar {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  align-items: center;
  z-index: 100;
}

$barBackgroundColor: #381048;

.BarContainer {
  position: relative;
  width: 100%;
  height: 15px;
  border: 1px solid black;
  background: $barBackgroundColor;
  background: linear-gradient(
    to right,
    $barBackgroundColor,
    darken($barBackgroundColor, 10)
  );
  box-sizing: border-box;
  box-shadow: 0 0 5px black;
  border-radius: 4px;
  overflow: hidden;
}

.Bar {
  position: absolute;
  left: 0;
  top: 0;

  background: magenta;
  box-shadow: inset -40px 0 60px -30px white;

  height: 100%;
  box-sizing: border-box;
  border-radius: 2px;

  transition: width 2s ease-out;

  overflow: hidden;

  &:after {
    position: absolute;
    left: 0%;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      120deg,
      rgba(white, 0) 0%,
      rgba(white, 0.13) 77%,
      rgba(white, 0.5) 92%,
      rgba(white, 0) 100%
    );
    animation: shine 10s linear infinite;
    animation-fill-mode: forwards;

    @keyframes shine {
      0% {
        opacity: 1;
        left: -100%;
        transition-property: left, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
      }
      100% {
        opacity: 0;
        left: 1000%;
        transition-property: left, opacity;
      }
    }
  }
}

.TextContainer {
  margin-top: 5px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  color: white;

  font-family: sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 2px 2px rgba(black, 0.7);
}

.label {
  flex: 1 0 400px;
  text-align: left;
}

.values {
  flex: 1 0 200px;
  text-align: right;
}
