.App {
  width: 100%;
  height: 100%;
  overflow: hidden;

  font-family: sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: white;
  text-shadow: 2px 2px rgba(black, 0.7);
}