.Logo {
  position: absolute;
  z-index: 0;
  overflow: show;
  white-space: pre;
  line-height: 0.85em;

  font-size: 50px;

  text-shadow: none;
  color: black;
}

.glitch {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  user-select: none;
  mix-blend-mode: screen;
}

@keyframes shift-1 {
  54.9% {
    left: 3px;
  }
  55% {
    left: 6px;
  }
  60% {
    left: 3px;
  }
}

@keyframes shift-2 {
  94.9% {
    left: -3px;
  }
  95% {
    left: -6px;
  }
  100% {
    left: -3px;
  }
}

@keyframes shift-3 {
  94.9% {
    left: 0;
  }
  95% {
    left: -3px;
  }
  100% {
    left: 0;
  }
}

.glitchCyan {
  left: 3px;
  top: 2px;
  animation: shift-1 4s infinite;
  color: cyan;
}

.glitchMagenta {
  left: -3px;
  top: 3px;
  animation: shift-2 5s infinite;
  color: magenta;
}

.glitchWhite {
  left: 0px;
  top: -2px;
  animation: shift-3 6s infinite;
  color: white;
}
