.Shape {
  position: absolute;
  background-color: black;
  mix-blend-mode: color-dodge;
}

.square {
  background-color: transparent;
  border: 6px solid black;
}

.circle {
  background-color: transparent;
  border: 6px solid black;
  border-radius: 50%;
}

.x {
  background-color: transparent;
  .a {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    left: -50%;
    top: -50%;
    transform: rotate(45deg);
  }
  .b {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    left: -50%;
    top: -50%;
    transform: rotate(-45deg);
  }
}

@mixin zig {
  width: var(--size);
  height: var(--size);
  border: $zigzag-width solid var(--color);
  border-left-width: 0;
  border-bottom-width: 0;
  box-sizing: border-box;
  background-color: transparent;
}

$zigzag-width: 6px;

.zigzag {
  --size: 20px;
  position: relative;
  @include zig();
  transform: rotate(-45deg);

  &:before {
    display: block;
    position: absolute;
    content: "";
    right: calc(var(--size) - #{$zigzag-width} * 2);
    bottom: calc(var(--size) - #{$zigzag-width});
    @include zig();
  }

  &:after {
    display: block;
    position: absolute;
    content: "";
    left: calc(var(--size) - #{$zigzag-width});
    top: calc(var(--size) - #{$zigzag-width} * 2);
    @include zig();
    border-right-width: 0;
  }
}
