.Tagline {
  color: white;

  white-space: pre;

  font-size: 20px;
  font-family: sans-serif;
  font-weight: bold;
  text-shadow: 2px 2px rgba(black, 0.7);
}
